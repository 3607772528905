<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo" to="/">
      <vuexy-logo/>
      <h2 class="brand-text text-primary ml-1">
        OYS - RESTORAN
      </h2>
    </b-link>
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1 mt-4">
          Kapalı Vardiya
        </h2>
        <b-button
            variant="success"
            class="mb-2 btn-sm-block mr-2"
            @click="openVardiya()"
        >
          Vardiyayı Başlat
        </b-button>
        <b-button
            variant="danger"
            class="mb-2 btn-sm-block"
            @click="logOut()"
        >
          Çıkış Yap
        </b-button>
        <b-img
            fluid
            :src="imgUrl"
            alt="Error page"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { BLink, BButton, BImg } from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import axios from 'axios'
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'
import router from '@/router'

export default {
  components: {
    VuexyLogo,
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  methods: {
    logOut() {
      store.state.isAuth = false
      store.commit('removeToken')
      store.commit('removeUserData')
      this.$router.push({ name: 'login' })
      localStorage.removeItem('restoranFirmaKodu')
      localStorage.removeItem('restoranUserName')
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      this.$ability.update(initialAbility)
      this.$router.push({ name: 'login' })
    },
    openVardiya() {
      const fd = this.postSchema()
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              this.$swal({
                icon: 'success',
                title: 'Başarılı',
                text: 'Vardiya Açıldı.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              store.state.isVardiya = true
              router.push({ name: 'dashBoard' })
            }
          })
    },
    postSchema() {
      const fd = new FormData()
      fd.append('crm_token', store.state.userToken || localStorage.getItem('restoranUserToken'))
      fd.append('serviceName', 'dashBoard')
      fd.append('methodName', 'vardiyaBaslat')
      return fd
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
